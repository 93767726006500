import { Password } from 'actions/user';
import { SimpleCard } from 'components/card';
import { Form } from 'components/form';
import { usePushToast } from 'context/toast';
import { useTranslation } from 'react-i18next';

const ForgotPwd = () => {
  const pushToast = usePushToast();
  const { t } = useTranslation();

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl px-8 lg:px-32 mx-auto flex flex-col lg:flex-row py-8 lg:py-24">
      <SimpleCard>
        <Form
          title={t('forgot_password.form.title')}
          titleSize="h3"
          inputs={[
            {
              placeholder: t('forgot_password.form.email.placeholder'),
              name: 'email',
              label: t('forgot_password.form.email.label'),
              type: 'email',
              required: true,
            },
          ]}
          buttonProps={{
            text: t('forgot_password.form.button.submit'),
            variant: 'success',
          }}
          handleSubmit={values =>
            new Password()
              .forgotPassword(values.email)
              .then(() => {
                pushToast({ text: t('forgot_password.form.toast.success'), variant: 'success' });
              })
              .catch(err => {
                pushToast({ text: t('forgot_password.form.toast.error'), variant: 'danger' });
                throw err;
              })
          }
        />
      </SimpleCard>
    </div>
  );
};

export default ForgotPwd;
