import { Party } from 'actions/party';
import { Music, Party as PartyModel } from 'model/app';
import { useRedirectIfNotLogged } from 'context/auth';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { InputGuesser } from 'components/input';
import { TopTable } from 'components/table';

const sortDate = (a: PartyModel, b: PartyModel) => b.date.getTime() - a.date.getTime();

const PartyTop: React.FC<{ id: string }> = ({ id }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [musics, setMusics] = useState<ReadonlyArray<Music>>([]);
  useEffect(() => {
    new Party()
      .getTop({ id })
      .then(({ items }) => setMusics(items))
      .finally(() => setLoading(false));
  }, [id, setLoading, setMusics]);

  return <TopTable data={musics} loading={isLoading} />;
};

const Top = () => {
  useRedirectIfNotLogged();
  const [parties, setParties] = useState<ReadonlyArray<PartyModel>>([]);
  const [selectedParty, setSelectedParty] = useState<string>();

  useEffect(() => {
    new Party().getMany().then(({ items }) => {
      setParties(items.toSorted(sortDate));
    });
  }, [setParties, sortDate]);

  const partyOptions = useMemo(
    () =>
      parties.map(p => ({ name: `${p.date.toLocaleDateString()} at ${p.place.address} - ${p.theme}`, value: p.id })),
    [parties],
  );

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl mx-auto flex flex-col gap-16 py-8 lg:py-24">
      <InputGuesser
        type="select"
        label="Select a party"
        options={partyOptions}
        placeholder="Select a party"
        onChange={(ev: ChangeEvent<HTMLSelectElement>) => setSelectedParty(ev.target.value)}
      />
      {selectedParty && <PartyTop id={selectedParty} />}
    </div>
  );
};

export default Top;
