import { PartyAPI } from 'model/api';
import { Party, Place as PlaceModel, User as UserModel } from 'model/app';
import { AppSerializer } from './app';
import { RequestInterface } from 'actions/abstract';
import { User } from 'actions/user';
import { Place } from 'actions/place';

export class PartySerializer extends AppSerializer<PartyAPI, Party> {
  serialize(o: PartyAPI, rq?: RequestInterface): Promise<Party> {
    return super.serialize(o, rq).then(party =>
      Promise.all([
        new User().getOne({ id: o.dj.split('/').pop() ?? '' }),
        new Place().getOne({ id: o.place.split('/').pop() ?? '' }),
      ]).then(([dj, place]): Party => {
        return {
          ...party,
          date: new Date(o.date),
          dj: dj as UserModel,
          place: place as PlaceModel,
        };
      }),
    );
  }

  deserialize(o: Party): Promise<PartyAPI> {
    return super.deserialize(o).then((party: PartyAPI) => ({
      ...party,
      place: o.place.id,
      dj: o.dj.id,
    }));
  }
}
