import { H2 as H2Base } from 'components/atomic';
import { AboutCard, UserCard } from 'components/card';
import React from 'react';
import { useTranslation } from 'react-i18next';

const H2: React.FC<React.PropsWithChildren> = ({ children }) => (
  <h2 className="text-white font-black text-2xl mx-auto justify-center text-center h-full">{children}</h2>
);

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full container px-4 lg:px-32 flex flex-col gap-16 mx-auto py-8 lg:py-24">
      <H2Base className="uppercase">{t('about.title')}</H2Base>
      <div className="uppercase shadow-none text-shadow-none">
        <H2>
          {t('about.title.meet_between')}{' '}
          <span className="text-transparent bg-gradient-to-r from-primary to-secondary bg-clip-text inline-block">
            {t('about.title.tech_event')}
          </span>
        </H2>
        <H2>{t('about.title.music_taste')}</H2>
      </div>
      <AboutCard>
        <p className="text-xl pb-4">{t('about.card.explanation.1.first')}</p>
        <p>{t('about.card.explanation.1.second')}</p>
      </AboutCard>
      <AboutCard>
        <p>{t('about.card.explanation.2.first')}</p>
        <p>{t('about.card.explanation.2.second')}</p>
        <p>{t('about.card.explanation.2.third')}</p>
      </AboutCard>
      <div>
        <H2Base className="uppercase">{t('about.team')}</H2Base>
        <div className="w-full lg:max-w-6xl px-16 pt-8 mx-auto">
          <div className="flex w-full lg:w-1/3 m-auto lg:px-4 pb-8">
            <UserCard firstname="Simon" job="CEO" image="simon" variant="secondary" />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 mx-auto gap-4 gap-8">
            <UserCard firstname="Sylvain" job="CTO" image="sylvain" variant="primary" />
            <UserCard firstname="Ali" job="Backend developer" image="ali" variant="primary" />
            <UserCard firstname="Léa" job="Frontend developer" image="lea" variant="primary" />
            <UserCard firstname="Flora" job="UX designer" image="flora" variant="secondary" />
            <UserCard firstname="Amina" job="Head of com" image="amina" variant="secondary" />
            <UserCard firstname="Fleur" job="Design & com expert" image="fleur" variant="secondary" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
