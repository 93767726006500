import { AlertIcon } from 'components/atomic/alert';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Validated = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-40 px-4">
      <div className="flex flex-col gap-8 text-center text-success">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className={`stroke-current shrink-0 w-12 h-12 mx-auto`}
        >
          <AlertIcon variant="success" />
        </svg>
        <h3 className="text-2xl md:text-3xl lg:text-4xl">{t('validated.alert.1')}</h3>
        <h3 className="text-2xl md:text-3xl lg:text-4xl">{t('validated.alert.2')}</h3>
      </div>
    </div>
  );
};

export default Validated;
