import { OutlinedButton } from 'components/atomic/button';
import { Dropdown } from 'components/atomic/dropdown';
import { Icon } from 'components/atomic/icon';
import { useDispatchAuth, useIsAuth, useMe } from 'context/auth';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { NavbarRoutes, ROUTES, navlink } from 'utils/routes';
import { Token } from 'utils/storage';

const topLimitDetection = 150;

const UserNavLink = () => {
  const { t } = useTranslation();
  const setConnected = useDispatchAuth();
  const { email } = useMe();
  const [isOpen, setOpen] = useState(false);

  return (
    <Dropdown
      action={
        <div className="inline-flex align-middle gap-2">
          <Icon className="my-auto" name="user" size={24} />
          {email}
        </div>
      }
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <li className="text-xl">
        <Link to={ROUTES.ACCOUNT}>{t('navbar.account.go_to')}</Link>
      </li>
      <li className="block pl-4">
        <OutlinedButton
          icon="user"
          variant="danger"
          text={t('account.logout')}
          onClick={() => {
            new Token().delete();
            setConnected(false);
          }}
        />
      </li>
    </Dropdown>
  );
};

const InstagramLink = {
  to: ROUTES.INSTAGRAM,
  children: (
    <NavLink to={ROUTES.INSTAGRAM} rel="noopener noreferrer" target="_blank" className="py-4 lg:py-2">
      <Icon name="instagram" size={32} />
    </NavLink>
  ),
};

const authNavlinks: ReadonlyArray<navlink> = [
  NavbarRoutes.Home,
  NavbarRoutes.Parties,
  NavbarRoutes.Places,
  NavbarRoutes.Users,
  NavbarRoutes.About,
  NavbarRoutes.Contact,
  {
    to: ROUTES.ACCOUNT,
    children: <UserNavLink />,
  },
  InstagramLink,
];

const guestNavlinks: ReadonlyArray<navlink> = [
  NavbarRoutes.Home,
  NavbarRoutes.About,
  NavbarRoutes.Contact,
  {
    type: 'button',
    to: ROUTES.LOGIN,
    children: {
      icon: 'user',
      variant: 'secondary',
      text: 'navbar.sign_in',
    },
  },
  InstagramLink,
];

const Navlinks = () => {
  const authenticated = useIsAuth();
  const { roles } = useMe();
  const { t } = useTranslation();

  const links = useMemo(() => {
    return authenticated ? authNavlinks : guestNavlinks;
  }, [authenticated]);

  return (
    <>
      {links
        .filter(link => link.guard?.(roles) ?? true)
        .map((l, idx) =>
          l.type === 'button' ? (
            <Link key={`${idx}-${l.to}`} to={ROUTES.LOGIN} className="w-fit">
              <OutlinedButton {...l.children} text={t(l.children.text ?? '')} />
            </Link>
          ) : (
            <li key={`${idx}-${l.to}`} className="text-primary-content">
              {l.children ?? (
                <NavLink to={l.to} {...(l.text ? { children: t(l.text) } : {})} className="py-4 lg:py-2" />
              )}
            </li>
          ),
        )}
    </>
  );
};

export const Navbar = () => {
  const drawerRef = useRef(null);
  const { pathname } = useLocation();
  const isNotLoginParty = !/^\/(parties\/\d+\/login|validated)$/.test(pathname);
  const [isTop, setTop] = useState(false);

  useEffect(() => {
    setTop(pathname === '/');

    if (drawerRef.current && (drawerRef.current as HTMLInputElement)) {
      (drawerRef.current as HTMLInputElement).checked = false;
    }
  }, [pathname]);

  useEffect(() => {
    const ev = () => {
      const current = document.documentElement.scrollTop;
      if (isTop && current > topLimitDetection) {
        setTop(false);
      } else if (!isTop && current <= topLimitDetection) {
        setTop(true);
      }
    };

    if (pathname !== '/') {
      document.removeEventListener('scroll', ev);
      return;
    }

    document.addEventListener('scroll', ev);
    return () => {
      document.removeEventListener('scroll', ev);
    };
  }, [pathname, isTop, setTop]);

  return (
    <div
      className={`drawer top-0 sticky w-full flex z-20 backdrop-blur-md transition-all duration-300 md:px-16 lg:px-32 ${
        isTop ? 'py-8' : 'shadow-lg py-4 bg-neutral/40'
      }`}
    >
      <input ref={drawerRef} id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content w-full">
        <div className="w-full navbar min-h-fit py-0">
          {isNotLoginParty && (
            <div className="flex-none lg:hidden">
              <label htmlFor="my-drawer-3" aria-label="open sidebar" className="btn btn-square btn-ghost">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block w-6 h-6 stroke-current"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </label>
            </div>
          )}
          <div className="flex-1 px-2 mx-2">
            {isNotLoginParty ? (
              <Link to={ROUTES.HOME} className={`mx-auto lg:mx-0 ${isTop ? 'hidden' : ''}`}>
                <img src="/images/djcopilot.png" className={`max-h-12 lg:max-h-12 w-auto`} />
              </Link>
            ) : (
              <img
                src="/images/djcopilot.png"
                className={`max-h-12 lg:max-h-12 w-auto mx-auto lg:mx-0 ${isTop ? 'hidden' : ''}`}
              />
            )}
          </div>
          {isNotLoginParty && (
            <div className="flex-none hidden lg:block">
              <ul className="menu menu-horizontal py-0 gap-4 text-lg">
                <Navlinks />
              </ul>
            </div>
          )}
        </div>
      </div>
      {isNotLoginParty && (
        <div className="drawer-side">
          <label htmlFor="my-drawer-3" aria-label="close sidebar" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 min-h-full backdrop-blur-md bg-app rounded-r-3xl text-2xl gap-4">
            <Navlinks />
          </ul>
        </div>
      )}
    </div>
  );
};
