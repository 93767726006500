import { PlaceAPI } from 'model/api';
import { Place } from 'model/app';
import { AppSerializer } from './app';
import { RequestInterface } from 'actions/abstract';
import { User } from 'actions/user';

export class PlaceSerializer extends AppSerializer<PlaceAPI, Place> {
  serialize(o: PlaceAPI, rq?: RequestInterface): Promise<Place> {
    return super.serialize(o, rq).then(place =>
      new User().getOne({ id: o.manager.split('/').pop() ?? '' }).then((manager): Place => {
        return {
          ...place,
          owner: manager,
        };
      }),
    );
  }

  deserialize(o: Place): Promise<PlaceAPI> {
    return super.deserialize(o).then(u => ({
      ...u,
      manager: o.owner['@id'] ?? '',
    }));
  }
}
