import React from 'react';

import './utils/global.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import About from 'pages/About';
import Home from 'pages/Home';
import Login from 'pages/Login';
import PartyLogin from 'pages/PartyLogin';
import Validated from 'pages/Validated';
import Party from 'pages/Party';
import Contact from 'pages/Contact';
import Places from 'pages/Places';
import Users from 'pages/Users';
import Activation from 'pages/Activation';
import Parties from 'pages/Parties';
import Top from 'pages/Top';
import Profile from 'pages/Profile';
import { Navbar } from './components/layout';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ToastProvider } from 'context/toast';
import { AuthProvider } from 'context/auth';
import ForgotPwd from 'pages/ForgotPwd';
import ResetPassword from 'pages/ResetPassword';
import { Language, Token } from 'utils/storage';
import { ROUTES } from 'utils/routes';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import fr from './translations/fr.json';

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: new Language().get() || undefined,
    fallbackLng: 'en',
  });

export const App = () => {
  const token = new Token().get();

  return (
    <I18nextProvider i18n={i18n}>
      <ToastProvider>
        <ParallaxProvider>
          <AuthProvider authenticated={!!token} requestToken={token}>
            <div className="min-h-screen">
              <BrowserRouter>
                <Navbar />
                <Routes>
                  <Route path={ROUTES.ABOUT_US} Component={About} />
                  <Route path={ROUTES.CONTACT_US} Component={Contact} />
                  <Route path={ROUTES.PARTY_VALIDATED} Component={Validated} />
                  <Route path={ROUTES.PLACES} Component={Places} />
                  <Route path={ROUTES.USERS} Component={Users} />
                  <Route path={ROUTES.PARTY_LOGIN} Component={PartyLogin} />
                  <Route path={ROUTES.FORGOT_PASSWORD} Component={ForgotPwd} />
                  <Route path={ROUTES.ACTIVATION} Component={Activation} />
                  <Route path={ROUTES.RESET_PASSWORD} Component={ResetPassword} />
                  <Route path={ROUTES.LOGIN} Component={Login} />
                  <Route path={ROUTES.PARTY} Component={Party} />
                  <Route path={ROUTES.TOP} Component={Top} />
                  <Route path={ROUTES.PARTIES} Component={Parties} />
                  <Route path={ROUTES.ACCOUNT} Component={Profile} />
                  <Route index path={ROUTES.HOME} Component={Home} />
                </Routes>
              </BrowserRouter>
            </div>
          </AuthProvider>
        </ParallaxProvider>
      </ToastProvider>
    </I18nextProvider>
  );
};
