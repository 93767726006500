import { UserAPI } from 'model/api';
import { User } from 'model/app';
import { AppSerializer } from './app';
import { RequestInterface } from 'actions/abstract';

export class UserSerializer extends AppSerializer<UserAPI, User> {
  serialize(o: UserAPI, rq?: RequestInterface): Promise<User> {
    return super.serialize(o, rq).then(user => ({
      ...user,
      username: o.email,
    }));
  }

  deserialize(o: User): Promise<UserAPI> {
    return super.deserialize(o).then(u => ({
      id: u.id,
      email: o.email,
      ...(o.password ? { password: o.password } : {}),
      ...(o.firstname ? { firstname: o.firstname } : {}),
      ...(o.lastname ? { lastname: o.lastname } : {}),
    }));
  }
}

export class DjSerializer extends UserSerializer {
  deserialize(o: User): Promise<UserAPI> {
    return super.deserialize(o).then(u => ({
      ...u,
      ...(o.username ? { username: o.username } : {}),
    }));
  }
}
