import { MusicAPI } from 'model/api';
import { Music } from 'model/app';
import { AppSerializer } from './app';
import { RequestInterface } from 'actions/abstract';
import { APISingleResult } from 'model';
import { ArtistSerializer } from './artist';

export class MusicSerializer extends AppSerializer<MusicAPI, Music> {
  serialize(music: APISingleResult<MusicAPI>, rq?: RequestInterface | undefined): Promise<Music> {
    const artistSerializer = new ArtistSerializer();
    return super.serialize(music, rq).then(async (m: Music) => ({
      ...m,
      artist: await artistSerializer.serialize(m.artist, rq),
    }));
  }
}
