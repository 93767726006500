import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  RawAxiosRequestHeaders,
} from 'axios';
import { AppSerializer } from 'serializers/app';
import { APIList, APIListResult, APIResource, APISingleResult, AppResource } from 'model';
import { SerializerInterface } from 'serializers/interface';
import { Token } from 'utils/storage';
import { ROUTES } from 'utils/routes';

interface EndpointInterface {
  endpoint?: string;
}

interface DepthLoaderInterface {
  depth?: number;
}

interface ConfigInterface {
  config?: AxiosRequestConfig;
}

interface Cache {
  cache?: Record<string, Record<number | string, object>>;
}

export type RequestInterface = DepthLoaderInterface & ConfigInterface & Cache;

const getHeaders = (): RawAxiosRequestHeaders => ({
  Accept: 'application/ld+json',
  'Content-Type': 'application/json',
});

export abstract class API {
  endpoint = '';
  filter = {};
  pagination = {};

  constructor({
    filter = {},
    pagination = {},
  }: { filter?: Record<string, string>; pagination?: { page?: number } } = {}) {
    this.filter = filter;
    this.pagination = pagination;
  }

  getBaseUrl = (): string => '';

  request(config?: CreateAxiosDefaults): AxiosInstance {
    const instance = axios.create({
      baseURL: this.getBaseUrl(),
      headers: {
        ...getHeaders(),
        ...(config?.headers ?? {}),
      },
      ...(config ?? {}),
    });
    instance.interceptors.request.use(r => {
      const token = new Token().get();
      if (token) {
        r.headers['Authorization'] = `Bearer ${token}`;
      }
      return r;
    });
    instance.interceptors.response.use(
      r => r,
      r => {
        if (r.message === 'Network Error') {
          return;
        }

        if (
          (!r.response || 401 === r.response.status || 403 === r.response.status) &&
          !(r.request.responseURL || r.config.url).includes('/login_check')
        ) {
          new Token().delete();
          if (typeof window !== 'undefined') {
            window.location.pathname = ROUTES.LOGIN;
          }
        }

        return Promise.reject(r);
      },
    );

    return instance;
  }

  async deleteRequest({ endpoint }: EndpointInterface = {}): Promise<AxiosResponse> {
    return this.request().delete(`${this.endpoint}${endpoint || ''}`);
  }

  async getRequest({ config, endpoint = '' }: ConfigInterface & EndpointInterface = {}): Promise<AxiosResponse> {
    return this.request(config).get(
      `${this.endpoint}${endpoint}?${
        new URLSearchParams({
          ...this.pagination,
          ...this.filter,
        }).toString() || ''
      }`,
    );
  }

  async postRequest({
    config = {},
    data,
    endpoint = '',
  }: AxiosRequestConfig & EndpointInterface & ConfigInterface = {}): Promise<AxiosResponse> {
    return this.request().post(`${this.endpoint}${endpoint}`, data, config);
  }

  async patchRequest({ data, endpoint }: AxiosRequestConfig & EndpointInterface = {}): Promise<AxiosResponse> {
    return this.request({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch(`${this.endpoint}${endpoint}`, JSON.stringify(data));
  }
}

export class App<T extends APIResource, U extends AppResource> extends API {
  protected serializer: SerializerInterface<T, U> = new AppSerializer();

  getBaseUrl = (): string => process.env.REACT_APP_API_URL ?? 'http://localhost:8080';

  getMany({ config, depth }: DepthLoaderInterface & ConfigInterface = {}): Promise<APIList<U>> {
    return this.getRequest({ config })
      .then(({ data }: { data: APIListResult<T> }) =>
        Promise.all([
          this.serializer.serializeMany(data['hydra:member'], {
            config,
            depth,
          }),
          data['hydra:totalItems'],
        ]),
      )
      .then(([items, total]) => ({
        items,
        total,
      }));
  }

  async create(data: U) {
    return this.postRequest({
      config: { headers: { 'Content-Type': 'application/ld+json' } },
      data: await this.serializer.deserialize(data),
    }).then(({ data: v }) => this.serializer.serialize(v));
  }

  getOne({ config, depth, id }: DepthLoaderInterface & ConfigInterface & APISingleResult) {
    return this.getRequest({ config, endpoint: `/${id}` }).then(({ data: v }) =>
      this.serializer.serialize(v, { config, depth }),
    );
  }

  async update(id: string, data: Partial<U>) {
    return this.patchRequest({ data: await this.serializer.deserialize(data as U), endpoint: `/${id}` }).then(
      ({ data: v }) => this.serializer.serialize(v),
    );
  }

  delete(id: string) {
    return this.deleteRequest({ endpoint: `/${id}` });
  }
}
