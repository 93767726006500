import React, { useEffect, useRef, useState } from 'react';
import { AppleButton as Apple, DeezerButton, H3, SpotifyButton } from 'components/atomic';
import { Party } from 'actions/party';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FeatureToggleProvider, useFeatureToggle } from 'context/ft';

const AppleButton: React.FC = () => {
  const party = new Party();
  const submitRef = useRef<HTMLButtonElement | null>(null);
  const { id = '' } = useParams();
  // eslint-disable-next-line
  const [appleInstance, setAppleInstance] = useState<any>();
  const [appleScriptLoaded, setAppleScriptLoaded] = useState(false);
  const [appleToken, setAppleToken] = useState<string>();
  const [mut, setMut] = useState<string>();

  useEffect(() => {
    const scr = document.createElement('script');
    scr.src = 'https://js-cdn.music.apple.com/musickit/v1/musickit.js';
    document.body.appendChild(scr);
    scr.onload = () => {
      setAppleScriptLoaded(true);
    };
  }, [setAppleScriptLoaded]);

  useEffect(() => {
    new Party().getAppleToken(id).then(setAppleToken);
  }, [id, setAppleToken]);

  useEffect(() => {
    if (!appleScriptLoaded || !appleToken) {
      return;
    }

    // eslint-disable-next-line
    const { MusicKit }: any = window;
    if (MusicKit) {
      MusicKit.configure({
        developerToken: appleToken,
      });
      setAppleInstance(MusicKit);
    }
  }, [appleScriptLoaded, appleToken]);

  return (
    <form action={`${party.getBaseUrl()}${party.getLoginEndpoint(id)}`} method="POST">
      <input type="hidden" name="type" value="apple" />
      {mut && <input type="hidden" name="token" value={mut} />}
      <Apple
        onClick={ev => {
          ev.preventDefault();
          appleInstance
            .getInstance()
            .authorize()
            .then((token: string) => {
              setMut(token);
              setTimeout(() => {
                submitRef.current?.click();
              }, 10);
            });
        }}
      />
      <button ref={submitRef} type="submit" className="hidden" />
    </form>
  );
};

const OAuthButton: React.FC<{ id: string; provider: 'Spotify' | 'Deezer' }> = ({ id, provider }) => {
  const Button = provider === 'Deezer' ? DeezerButton : SpotifyButton;
  const party = new Party();

  return (
    <form action={`${party.getBaseUrl()}${party.getLoginEndpoint(id)}`} method="post">
      <input type="hidden" name="type" value={provider.toLowerCase()} />
      <Button className="w-full" />
    </form>
  );
};

const PartyLogin = () => {
  const isSpotifyEnabled = useFeatureToggle('SPOTIFY_RETRIEVER');
  const { t } = useTranslation();
  const { id = '' } = useParams();

  return (
    <>
      <H3 className="pt-16 px-16">{t('party_login.title')}</H3>
      <div className="max-w-xl mx-auto flex flex-col gap-8 px-24 py-8 lg:px-32 lg:py-16">
        <AppleButton />
        {isSpotifyEnabled && <OAuthButton id={id} provider="Spotify" />}
        <OAuthButton id={id} provider="Deezer" />
      </div>
    </>
  );
};

export default () => {
  return (
    <FeatureToggleProvider>
      <PartyLogin />
    </FeatureToggleProvider>
  );
};
