import { APIToken, UserLogin } from 'model';
import { App } from './abstract';
import { DjSerializer, SerializerInterface, UserSerializer } from 'serializers';
import { AxiosResponse } from 'axios';
import { Token } from 'utils/storage';
import { DjAPI, UserAPI } from 'model/api';
import { User as UserModel } from 'model/app';

export class User extends App<UserAPI, UserModel> {
  endpoint = '/users';
  protected serializer: SerializerInterface<UserAPI, UserModel> = new UserSerializer();
}

export class Attendee extends User {
  endpoint = '/attendees';
}

export class Dj extends User {
  endpoint = '/djs';
  protected serializer: SerializerInterface<DjAPI, UserModel> = new DjSerializer();
}

export class Manager extends User {
  endpoint = '/managers';
}

export class Admin extends User {
  endpoint = '/admins';
}

export class Auth extends User {
  endpoint = '/login_check';

  login(data: UserLogin): Promise<boolean> {
    const tokenInstance = new Token();
    tokenInstance.delete();
    return this.postRequest({ data }).then(({ data: { token } }: AxiosResponse<APIToken>) => {
      tokenInstance.set(token);

      return true;
    });
  }
}

export class Password extends User {
  endpoint = '';

  forgotPassword(email: string) {
    return this.postRequest({ data: { email }, endpoint: '/forgot-password' });
  }

  resetPassword(password: string, email: string, token: string) {
    return this.postRequest({ data: { password, email, token }, endpoint: '/reset-password' });
  }
}
