import { PlaceAPI } from 'model/api';
import { Place as PlaceModel } from 'model/app';
import { App } from './abstract';
import { SerializerInterface } from 'serializers';
import { PlaceSerializer } from 'serializers/place';

export class Place extends App<PlaceAPI, PlaceModel> {
  endpoint = '/places';
  protected serializer: SerializerInterface<PlaceAPI, PlaceModel> = new PlaceSerializer();
}
