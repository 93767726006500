import { User as UserModel } from 'model/app';
import { useDispatchAuth, useMe, useRedirectIfNotLogged } from 'context/auth';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { H2, H3, OutlinedButton } from 'components/atomic';
import { User } from 'actions/user';
import { Language, Token } from 'utils/storage';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { InputGuesser } from 'components/input';
import { Form } from 'components/form';
import { SimpleCard } from 'components/card';
import { usePushToast } from 'context/toast';

const Party = () => {
  useRedirectIfNotLogged();

  const pushToast = usePushToast();
  const { t } = useTranslation();
  const setConnected = useDispatchAuth();
  const { id, roles } = useMe();
  const [, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<UserModel>();

  useEffect(() => {
    new User()
      .getOne({ id })
      .then(setUser)
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl mx-auto flex flex-col gap-16 py-8 lg:py-24 px-4 lg:px-16 lg:px-0">
      <H2>{t('account.title', { username: user?.username })}</H2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
        <div className="flex flex-col gap-8 pt-16">
          <H3 className="p-0 m-none">{t('account.actions')}</H3>
          <InputGuesser
            type="select"
            label={t('account.change_language')}
            onChange={({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
              new Language().set(value);
              i18next.changeLanguage(value);
            }}
            defaultValue={i18next.language.slice(0, 2)}
            options={[
              { name: 'English', value: 'en' },
              { name: 'Français', value: 'fr' },
            ]}
          />
          <div>
            <OutlinedButton
              variant="danger"
              onClick={() => {
                new Token().delete();
                setConnected(false);
              }}
              text={t('account.logout')}
            />
          </div>
        </div>
        <div>
          <SimpleCard>
            <Form
              inputs={[
                {
                  defaultValue: user?.email,
                  id: 'email',
                  label: t('account.form.email.label'),
                  name: 'email',
                  disabled: true,
                },
                {
                  defaultValue: user?.username,
                  id: 'username',
                  label: t('account.form.username.label'),
                  name: 'username',
                },
                {
                  id: 'password',
                  label: t('account.form.password.label'),
                  placeholder: t('common.form.password.placeholder'),
                  name: 'password',
                  required: false,
                  type: 'password',
                },
                {
                  id: 'confirmPassword',
                  label: t('account.form.password.label'),
                  placeholder: t('common.form.password.placeholder'),
                  name: 'confirmPassword',
                  required: false,
                  type: 'password',
                },
              ]}
              title="Mes informations"
              buttonProps={{
                text: t('account.form.submit'),
                variant: 'success',
              }}
              handleSubmit={async data => {
                new User()
                  .update(id, { ...data, roles })
                  .then(() => {
                    pushToast({ text: t('account.form.toast.success'), variant: 'success' });
                  })
                  .catch(() => {
                    pushToast({ text: t('account.form.toast.error'), variant: 'warning' });
                  });
              }}
              validate={values => {
                if (values.password !== values.confirmPassword) {
                  return [t('account.form.validation.password.error')];
                }

                return [];
              }}
            />
          </SimpleCard>
        </div>
      </div>
    </div>
  );
};

export default Party;
