import { useGranted, useRedirectIfNotLogged } from 'context/auth';
import React, { useEffect, useState } from 'react';
import { UserTable } from 'components/table';
import { BaseButton, H2, SimpleModal } from 'components/atomic';
import { Form } from 'components/form';
import { Admin, Attendee, Dj, Manager, User } from 'actions/user';
import { useTranslation } from 'react-i18next';
import { usePushToast } from 'context/toast';
import { User as UserModel } from 'model/app';
import { ROLES } from 'utils/common';

const CreatePlace = () => {
  const { t } = useTranslation();
  const pushToast = usePushToast();
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <BaseButton
        className="w-fit text-white"
        variant="primary"
        icon="plus-square"
        text={t('user.create.title')}
        onClick={() => {
          setOpen(true);
        }}
      />
      {isOpen && (
        <SimpleModal isOpen onClose={() => setOpen(false)}>
          <Form
            title={t('user.create.title')}
            titleSize="h3"
            inputs={[
              {
                name: 'email',
                label: t('user.create.form.email.label'),
                placeholder: t('user.create.form.email.placeholder'),
                type: 'email',
              },
              {
                name: 'role',
                label: t('user.create.form.role.label'),
                type: 'select',
                defaultValue: ROLES.USER,
                required: true,
                options: Object.values(ROLES).map(value => ({ name: t(`role.${value.toLowerCase()}`), value })),
              },
              {
                name: 'firstname',
                label: t('user.create.form.firstname.label'),
                placeholder: t('user.create.form.firstname.placeholder'),
              },
              {
                name: 'lastname',
                label: t('user.create.form.lastname.label'),
                placeholder: t('user.create.form.lastname.placeholder'),
              },
              {
                name: 'username',
                label: t('user.create.form.username.label'),
                placeholder: t('user.create.form.username.placeholder'),
              },
            ]}
            buttonProps={{
              text: t('common.form.create'),
              variant: 'success',
            }}
            handleSubmit={values => {
              let instance = new Attendee();

              switch (values.role) {
                case ROLES.ADMIN:
                  instance = new Admin();
                  break;
                case ROLES.MANAGER:
                  instance = new Manager();
                  break;
                case ROLES.DJ:
                  instance = new Dj();
                  break;
              }

              return instance
                .create(values)
                .then(() => {
                  setOpen(false);
                  pushToast({ text: t('user.create.success'), variant: 'success' });
                })
                .catch(err => {
                  pushToast({ text: t('user.create.fail'), variant: 'danger' });
                  throw err;
                });
            }}
          />
        </SimpleModal>
      )}
    </>
  );
};

const Parties = () => {
  useRedirectIfNotLogged();
  const { t } = useTranslation();
  const canCreatePlace = useGranted(ROLES.MANAGER);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<ReadonlyArray<UserModel>>([]);

  useEffect(() => {
    new User()
      .getMany()
      .then(({ items }): void => {
        setUsers(items);
      })
      .finally(() => setIsLoading(false));
  }, [setUsers]);

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl mx-auto flex flex-col gap-16 py-8 lg:py-24 px-4 lg:px-16">
      <H2 className="md:text-7xl pb-8">{t('users.title')}</H2>
      {canCreatePlace && <CreatePlace />}
      <UserTable data={users} loading={isLoading} />
    </div>
  );
};

export default Parties;
