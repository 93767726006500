import { MusicAPI, PartyAPI } from 'model/api';
import { Music, Party as PartyModel } from 'model/app';
import { App } from './abstract';
import { SerializerInterface } from 'serializers';
import { PartySerializer } from 'serializers/party';
import { APIList, APIListResult } from 'model';
import { MusicSerializer } from 'serializers/music';

export class Party extends App<PartyAPI, PartyModel> {
  endpoint = '/parties';
  protected serializer: SerializerInterface<PartyAPI, PartyModel> = new PartySerializer();

  getLoginEndpoint(id: string) {
    return `${this.endpoint}/${id}/login`;
  }

  login(id: string, type: 'apple' | 'spotify' | 'deezer', token: string) {
    return this.postRequest({
      data: { token, type },
      endpoint: `/${id}/login`,
    });
  }

  getAppleToken(id: string): Promise<string> {
    return this.getRequest({
      endpoint: `/${id}/apple-token`,
    }).then(({ data: { token } }) => token);
  }

  getThemes(): Promise<ReadonlyArray<string>> {
    return this.getRequest({ endpoint: `/theme` }).then(({ data }) => data);
  }

  getTop({ id }: { id: string }): Promise<APIList<Music>> {
    return this.getRequest({ endpoint: `/${id}/top` }).then(
      ({ data: { 'hydra:member': v } }: { data: APIListResult<MusicAPI> }) => {
        const serializer = new MusicSerializer();
        return serializer.serializeMany(v).then(items => {
          return {
            items,
            total: items.length,
          };
        });
      },
    );
  }
}
