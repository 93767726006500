import { Place as PlaceModel } from 'model/app';
import { useGranted, useRedirectIfNotLogged } from 'context/auth';
import React, { useEffect, useState } from 'react';
import { PlaceTable } from 'components/table';
import { BaseButton, H2, SimpleModal } from 'components/atomic';
import { Form } from 'components/form';
import { Place } from 'actions/place';
import { Selectable, option } from 'components/input';
import { Manager } from 'actions/user';
import { useTranslation } from 'react-i18next';
import { usePushToast } from 'context/toast';
import { ROLES } from 'utils/common';

const CreatePlace = () => {
  const { t } = useTranslation();
  const pushToast = usePushToast();
  const [isOpen, setOpen] = useState(false);
  const [users, setUsers] = useState<ReadonlyArray<option>>([]);

  useEffect(() => {
    new Manager().getMany().then(({ items }): void => {
      setUsers(items.map(user => ({ name: user.email, value: user['@id'] ?? '' })));
    });
  }, [setUsers]);

  return (
    <>
      <BaseButton
        className="w-fit text-white"
        variant="primary"
        icon="plus-square"
        text={t('place.create.title')}
        onClick={() => {
          setOpen(true);
        }}
      />
      {isOpen && (
        <SimpleModal isOpen onClose={() => setOpen(false)}>
          <Form
            title={t('place.create.title')}
            titleSize="h3"
            inputs={[
              {
                name: 'name',
                label: t('place.create.form.name.label'),
                placeholder: t('place.create.form.name.placeholder'),
              },
              {
                name: 'address',
                label: t('place.create.form.address.label'),
                placeholder: t('place.create.form.address.placeholder'),
              },
              {
                placeholder: t('place.create.form.user.placeholder'),
                defaultValue: '',
                name: 'user',
                label: t('place.create.form.user.label'),
                type: 'select',
                options: users,
                required: true,
              } as Selectable,
            ]}
            buttonProps={{
              text: t('common.form.create'),
              variant: 'success',
            }}
            handleSubmit={values =>
              new Place()
                .create({
                  ...values,
                  owner: {
                    '@id': values['user'],
                  },
                })
                .then(() => {
                  setOpen(false);
                  pushToast({ text: t('place.create.success'), variant: 'success' });
                })
                .catch(err => {
                  pushToast({ text: t('place.create.fail'), variant: 'danger' });
                  throw err;
                })
            }
          />
        </SimpleModal>
      )}
    </>
  );
};

const Parties = () => {
  useRedirectIfNotLogged();
  const { t } = useTranslation();
  const canCreatePlace = useGranted(ROLES.MANAGER);
  const [isLoading, setIsLoading] = useState(true);
  const [places, setPlaces] = useState<ReadonlyArray<PlaceModel>>([]);

  useEffect(() => {
    new Place()
      .getMany()
      .then(({ items }) => {
        setPlaces(items);
      })
      .finally(() => setIsLoading(false));
  }, [setPlaces]);

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl mx-auto flex flex-col gap-16 py-8 lg:py-24 px-4 lg:px-16">
      <H2 className="md:text-7xl pb-8">{t('places.title')}</H2>
      {canCreatePlace && <CreatePlace />}
      <PlaceTable data={places} loading={isLoading} />
    </div>
  );
};

export default Parties;
