import axios from 'axios';

const BASE_URL = 'https://flags.devcv.fr';
const NAMESPACE = 'dj-copilot';

type flag = {
  flagKey: string;
  entityId: string;
};
export type AllowedFlags = 'SPOTIFY_RETRIEVER';

const FLAGS: Record<AllowedFlags, flag> = {
  SPOTIFY_RETRIEVER: {
    flagKey: 'spotify-retriever',
    entityId: 'f21cb5e7-3d79-474a-b5e2-a94d46010f92',
  },
};

const getFeatureToggle = (endpoint: string, flagname: AllowedFlags) => {
  const flagValue = FLAGS[flagname];
  return axios.post(`${BASE_URL}${endpoint}`, {
    ...flagValue,
    context: {},
    namespaceKey: NAMESPACE,
  });
};

const getBooleanFeatureToggle = (flagname: AllowedFlags) => getFeatureToggle('/evaluate/v1/boolean', flagname);

export const getSpotifyFeatureToggle = () => getBooleanFeatureToggle('SPOTIFY_RETRIEVER');
