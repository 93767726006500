import { buttonType } from 'components/atomic';
import { ROLES } from './common';

export const ROUTES = {
  ABOUT_US: '/about',
  CONTACT_US: '/contact',
  HOME: '/',
  PARTIES: '/parties',
  PLACES: '/places',
  USERS: '/users',
  PARTY: '/parties/:id',
  PARTY_LOGIN: '/parties/:id/login',
  PARTY_VALIDATED: '/validated',
  ACCOUNT: '/account',
  LOGIN: '/login',
  TOP: '/top',
  INSTAGRAM: 'https://www.instagram.com/dj_copilot/',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  ACTIVATION: '/activation',
} as const;

export type navlink = {
  to: (typeof ROUTES)[keyof typeof ROUTES];
  guard?: (roles: ReadonlyArray<string>) => boolean;
} & (
  | {
      type: 'button';
      children: buttonType & { text: string };
      text?: string;
    }
  | {
      type?: undefined;
      children?: React.ReactNode;
      text?: string;
    }
);

export const NavbarRoutes: Record<string, navlink> = {
  Home: {
    to: ROUTES.HOME,
    text: 'navbar.home',
  },
  About: {
    to: ROUTES.ABOUT_US,
    text: 'navbar.about_us',
  },
  Contact: {
    to: ROUTES.CONTACT_US,
    text: 'navbar.contact_us',
  },
  Parties: {
    to: ROUTES.PARTIES,
    text: 'navbar.parties',
  },
  Places: {
    to: ROUTES.PLACES,
    text: 'navbar.places',
    guard: (roles = []) => roles.includes(ROLES.ADMIN),
  },
  Users: {
    to: ROUTES.USERS,
    text: 'navbar.users',
    guard: (roles = []) => roles.includes(ROLES.ADMIN),
  },
};
