import React, { useContext, useEffect, useState } from 'react';
import { AllowedFlags, getSpotifyFeatureToggle } from 'flags';

type flagResult = {
  enabled: boolean;
  flagKey: 'spotify-retriever';
};

const FeatureToggleContext = React.createContext<Record<AllowedFlags, flagResult> | undefined>(undefined);

export const FeatureToggleProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [toggles, setToggles] = useState<Record<AllowedFlags, flagResult>>();

  useEffect(() => {
    getSpotifyFeatureToggle().then(({ data }) => {
      setToggles({
        SPOTIFY_RETRIEVER: data.enabled,
      });
    });
  }, []);

  return <FeatureToggleContext.Provider value={toggles}>{children}</FeatureToggleContext.Provider>;
};

export const useFeatureToggle = (name: AllowedFlags) => {
  const toggles = useContext(FeatureToggleContext);

  return toggles?.[name];
};
