export type AllowedVariant =
  | 'default'
  | 'neutral'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'ghost'
  | 'info'
  | 'warning';

export type ClassName = { className?: string };

export const ROLES = {
  ADMIN: 'ROLE_ADMIN',
  ATTENDEE: 'ROLE_ATTENDEE',
  DJ: 'ROLE_DJ',
  MANAGER: 'ROLE_MANAGER',
  USER: 'ROLE_USER',
};
